import urlcat from 'urlcat';
import { staticApi } from './endpoints';
import { fetchWrapped } from './';

const getCategoriesList = async () => await fetchWrapped(urlcat(staticApi, `/categories_list.json`));
const getCategoriesList2 = async () => await fetchWrapped(urlcat(staticApi, `/categories_list2.json`));

//todo: temporarily deprecated. Now we use json files right away from /src/config
//const getInitData = async () => await fetchWrapped(urlcat(staticApi, '/init.json'));
const getCategoriesRoutes = async () => await fetchWrapped(urlcat(staticApi, '/categories_routes.json'));
// const getSlidersData = async () => await fetchWrapped(urlcat(staticApi, `/homepage_sliders.json`));
const getShopsMainCategories = async () => await fetchWrapped(urlcat(staticApi, '/main-categories.json'));
const getCategoriesData = async (joinedCategories) =>
  await fetchWrapped(urlcat(staticApi, `/categories/${joinedCategories}.json`));
const getCategories2Data = async (joinedCategories) =>
  await fetchWrapped(urlcat(staticApi, `/categories2/${joinedCategories}.json`));
const getFeaturedCategories = async () => await fetchWrapped(urlcat(staticApi, '/featured-categories.json'));
const getMarketStatus = async () => await fetchWrapped(urlcat(staticApi, '/market-status.json'));
const getAllShops = async () => await fetchWrapped(urlcat(staticApi, '/all-shops.json'));

export default {
  // getInitData,
  getCategoriesList,
  getCategoriesList2,
  getFeaturedCategories,
  getCategoriesRoutes,
  // getSlidersData,
  getShopsMainCategories,
  getCategoriesData,
  getCategories2Data,
  getMarketStatus,
  getAllShops,
};
